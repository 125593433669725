import store from '@/store'
import Axios from 'axios'
import ISupportPolicy from './models/ISupportPolicy'

export interface ISupportPolicyStoreState {
  loading: boolean,
  supportPolicies: ISupportPolicy[]
}

store.registerModule<ISupportPolicyStoreState>('SupportPolicies', {
  namespaced: true,
  getters: {
    supportPolicies(state) {
      return state.supportPolicies || []
    },
    getSupportPolicyById: state => (id: number) => {
      return state.supportPolicies.find(x => x.id === id)
    },
    getSupportPolicyByName: state => (name: string) => {
      return state.supportPolicies.find(x => x.name === name)
    },
    productFilterForSupportPolicy: state => (id: number) => {
      if (id) {
        const supportPolicy = state.supportPolicies.find(x => x.id === id)
        return `supportPolicy eq '${supportPolicy.name}'`
      }
      return ''
    }
  },
  mutations: {
    setLoading(state, loading: boolean) {
      state.loading = loading
    },
    setSupportPolicies(state, supportPolicies: ISupportPolicy[]) {
      state.supportPolicies = supportPolicies
    }
  },
  actions: {
    async load({ commit }) {
      try {
        commit('setLoading', true)
        const options = {
          params: {
            $orderby: 'name'
          }
        }
        const response = await Axios.get('GraphSupportPolicies', options)
        commit('setSupportPolicies', response.data.value)
      } finally {
        commit('setLoading', false)
      }
    }
  }
})
