






































import Vue from 'vue'
import moment, { ISO_8601, Moment } from 'moment'

export default Vue.extend({
  props: {
    dense: Boolean,
    disabled: Boolean,
    value: String
  },
  computed: {
    date(): Moment {
      const date = moment(this.value, ISO_8601, true)

      if (date.isValid()) {
        return date
      }
      return null
    },
    hour(): string {
      return this.date?.format('hh')
    },
    minute(): string {
      return this.date?.format('mm')
    },
    amPm(): string {
      return this.date?.format('A') || 'AM'
    }
  },
  methods: {
    toggleAmPm() {
      const date = this.date
      const hour = date.hour()
      if (this.amPm === 'AM') {
        date.hour(hour + 12)
      } else {
        date.hour(hour - 12)
      }
      this.$emit('input', date.toISOString())
    },
    setHour(hour) {
      const date = this.date
      let hh = +hour
      if (this.amPm === 'PM') {
        if (hh === 12) {
          hh = 0
        } else {
          hh += 12
        }
      }
      date.hour(hh)
      this.$emit('input', date.toISOString())
    },
    setMinute(minute) {
      const date = this.date.minute(minute)
      this.$emit('input', date.toISOString())
    }
  }
})
