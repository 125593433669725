<style scoped>
a {
  text-decoration: none;
  color: inherit;
}
</style>

<style>
div.v-toolbar__content {
  max-width: 1399px;
  margin: auto;
}
</style>

<template>
  <div>
    <v-app-bar style="z-index: 9999;" flat>
      <v-toolbar-title style="display: contents;">
        <router-link :to="{ name: 'Home' }" class="grey--text">
          <v-row align="center" style="width: 180px" no-gutters>
          <v-img
              src="/logo.png"
              max-width="54"
              aspect-ratio="1"
              class="d-inline mr-2"
            /><span style="color: #666666">EOS Insights</span>
          </v-row>
        </router-link>
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="hidden-md-and-up">
        <v-spacer />
        <v-menu
          bottom
          left
          attach
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>$bars</v-icon>
            </v-btn>
          </template>
          <v-list dense nav>
            <v-list-item :to="{ name: 'CustomerLookup' }" :disabled="disabled">
              Customer Lookup
            </v-list-item>
            <v-list-item :to="{ name: 'CustomerDetails' }" :disabled="disabled">
              Customer Details
            </v-list-item>
            <v-list-item :to="{ name: 'ReportBuilder' }" :disabled="disabled">
              Report Builder
            </v-list-item>
            <v-list-item :to="{ name: 'Dashboard' }" :disabled="disabled">
              Regions (PBI)
            </v-list-item>
            <v-list-item :to="{ name: 'Reports' }" :disabled="disabled">
              Saved Reports
            </v-list-item>
            <v-list-item :to="{ name: 'Timeline' }" :disabled="disabled">
              Timeline
            </v-list-item>
            <v-list-item :to="{ name: 'FAQ' }" :disabled="disabled">
              FAQ
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-toolbar-items>
          <!-- <v-divider vertical />
          <v-btn text :to="{ name: 'Home' }"
            >Home</v-btn
          > -->
          <v-divider vertical />
          <v-btn text :to="{ name: 'CustomerLookup' }" :disabled="disabled"
            ><span v-if="$vuetify.breakpoint.md" style="width: 75px; white-space: normal;">Customer Lookup</span>
            <span v-else>Customer Lookup</span></v-btn
          >
          <v-divider vertical />
          <v-btn text :to="{ name: 'CustomerDetails' }" :disabled="disabled"
            ><span v-if="$vuetify.breakpoint.md" style="width: 75px; white-space: normal;">Customer Details</span>
            <span v-else>Customer Details</span></v-btn
          >
          <v-divider vertical />
          <v-btn text :to="{ name: 'ReportBuilder' }" :disabled="disabled"
            ><span v-if="$vuetify.breakpoint.md" style="width: 75px; white-space: normal;">Report Builder</span>
            <span v-else>Report Builder</span></v-btn
          >
          <v-divider vertical />
          <v-btn text :to="{ name: 'Dashboard' }" :disabled="disabled"
            ><span v-if="$vuetify.breakpoint.md" style="width: 75px; white-space: normal;">Regions (PBI)</span>
            <span v-else>Regions (PBI)</span></v-btn
          >
          <v-divider vertical />
          <v-btn text :to="{ name: 'Reports' }" :disabled="disabled"
            ><span v-if="$vuetify.breakpoint.md" style="width: 75px; white-space: normal;">Saved Reports</span>
            <span v-else>Saved Reports</span></v-btn
          >
          <v-divider vertical />
          <v-btn text :to="{ name: 'Timeline' }" :disabled="disabled"
            ><span v-if="$vuetify.breakpoint.md" style="width: 75px; white-space: normal;">Timeline</span>
            <span v-else>Timeline</span></v-btn
          >
          <v-divider vertical />
          <v-btn text :to="{ name: 'FAQ' }" :disabled="disabled"
            >FAQ</v-btn
          >
          <v-divider vertical />
        </v-toolbar-items>
        <v-spacer  />
      </template>
      <!-- <v-btn icon small download href="./user_guide.docx">
        <v-icon small>help</v-icon>
      </v-btn> -->
      <v-toolbar-title>
        <img v-if="showPhoto" style="height: 48px; width: 48px; border-radius: 50%;" :src="photo" />
        <v-icon v-else>$user</v-icon>
        <!-- <span>Welcome {{ userName }}</span
            > -->
        <!-- <v-menu nudge-bottom="45">
          <template #activator="{ on }">
            <span style="cursor: pointer" v-on="on"
              >Welcome {{ userName }}</span
            >
          </template>
          <v-list>
            <v-list-item @click="logout">
              <v-list-item-icon>
                <v-icon>$logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu> -->
      </v-toolbar-title>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  props: {
    disabled: Boolean
  },
  components: {
  },
  data: () => ({
  }),
  computed: {
    userName() {
      const account = this.$auth.getAccount()

      if (account) {
        let name = account.name

        if (account.idTokenClaims) {
          const givenNameClaim = account.idTokenClaims.given_name
          if (givenNameClaim) {
            name = givenNameClaim
          }
        }

        return name
      }

      return ''
    },
    showPhoto() {
      return this.$store.getters['User/photo'] !== null
    },
    photo() {
      // const urlCreator = window.URL || window.webkitURL
      // console.log(this.$store.getters['User/photo'])
      // const imageUrl = urlCreator.createObjectURL(this.$store.getters['User/photo'])
      // console.log(imageUrl)
      return 'data:image/jpeg;base64,' + this.$store.getters['User/photo']
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('Auth/logout')
    }
  }
}
</script>
