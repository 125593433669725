import Axios from 'axios'
import moment from 'moment'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import ODataResult from '@/models/ODataResult'
import IQuery from '@/areas/queries/models/IQuery'
import ITemplate from '@/areas/templates/models/ITemplate'

extend('required', { ...required, message: 'This field is required.' })
extend('date', {
  validate(value: string) {
    return moment(value, 'MM/DD/YYYY', true).isValid()
  },
  message: 'This is not a valid date.'
})
extend('url', {
  validate(value: string) {
    const regex = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
    return !value || regex.test(value)
  },
  message: 'This is not a valid URL.'
})
extend('query_name', {
  validate(value: string) {
    const regex = /^[\w\-. ]+$/i
    return !value || regex.test(value)
  },
  message: 'Report name cannot contain special characters.'
})
extend('query_name_unique', {
  async validate(value, params) {
    let $filter = `name eq '${value}'`
    const id = params[0]
    const existingQuery = !isNaN(id)
    const copy = params[1]
    if (existingQuery && (typeof copy === 'undefined' || copy !== 'true')) {
      $filter += ` and id ne ${id}`
    }
    const response = await Axios.get<ODataResult<IQuery>>('GraphQueries/MyQueries?$count=true&$top=0', { params: { $filter } })
    return new ODataResult<IQuery>(response).count === 0
  },
  message: 'This report name already exists.'
})
extend('template_name_unique', {
  async validate(value, params) {
    let $filter = `name eq '${encodeURI(value).replace(/'/g, '%27')}'`
    const id = params[0]
    if (!isNaN(id)) {
      $filter += ` and id ne ${id}`
    }
    const response = await Axios.get<ODataResult<ITemplate>>('GraphTemplates/MyTemplates?$count=true&$top=0', { params: { $filter } })
    return new ODataResult<ITemplate>(response).count === 0
  },
  message: 'This template name already exists.'
})
extend('feedback_form', {
  async validate(value) {
    return !(value.stars === 0 && value.comment.length === 0)
  },
  message: 'Either stars or a comment is required.'
})
