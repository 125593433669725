










import Vue from 'vue'

export default Vue.extend({
  model: {
    prop: 'inputValue',
    event: 'change'
  }
})
