
















import Vue from 'vue'
import UserFeedbackForm from './UserFeedbackForm.vue'
import IUserFeedback from '../models/IUserFeedback'
import { ValidationProvider } from 'vee-validate'

export default Vue.extend({
  components: {
    UserFeedbackForm,
    ValidationProvider
  },
  props: {
    value: Object as () => IUserFeedback
  },
  data() {
    return {
      form: null
    }
  },
  computed: {
  },
  methods: {
    onSubmit() {
      this.$emit('submit', this.form || this.value)
    }
  }
})
