import store from '@/store'
import ODataStore, { ODataState, ODataRequest } from '@/store/ODataStore'
import IQuery from './models/IQuery'

export interface IQueryStoreState extends Partial<ODataState<IQuery>> {
}

store.registerModule('Queries', ODataStore.extend<IQueryStoreState>({
  namespaced: true,
  state: {
  },
  getters: {
    results(state) {
      return state.result.value
    }
  },
  actions: {
    async load({ commit, dispatch }, noLoading: boolean = false) {
      const request: ODataRequest = {
        url: 'GraphQueries/MyQueries',
        params: {
          $orderby: 'name'
        }
      }
      if (noLoading) {
        request.noLoading = true
      }
      const result = await dispatch('get', request)
      commit('setResult', result)
    },
    async deleteQuery({ dispatch }, query: IQuery) {
      const request: ODataRequest = {
        url: `GraphQueries(${query.id})`
      }
      await dispatch('delete', request)
      await dispatch('load')
    },
    async saveQuery({ dispatch }, query: IQuery) {
      const request: ODataRequest = {
        url: 'GraphQueries',
        data: query
      }
      const result = await dispatch('post', request)
      await dispatch('load')

      return result.id
    },
    copyQuery({ dispatch }, query: IQuery) {
      query.id = undefined
      query.uniqueId = undefined
      query.modifiedDate = undefined
      query.modifiedBy = undefined
      query.graphQueryProducts.forEach(x => {
        x.id = undefined
        x.uniqueId = undefined
        x.modifiedDate = undefined
        x.modifiedBy = undefined
      })
      return dispatch('saveQuery', query)
    },
    async generateDocument({ dispatch }, query: IQuery) {
      const request: ODataRequest = {
        url: `GraphQueries(${query.id})/Generate`,
        responseType: 'blob',
        noLoading: true
      }
      const response = await dispatch('get', request)
      const blob = new Blob([response], {
        type: response.type
      })
      const downloadUrl = window.URL.createObjectURL(blob)

      const link = document.createElement('a')

      link.href = downloadUrl
      const documentName = query.isTemporary ? 'Product Lifecycle' : query.name

      link.setAttribute('download', `${documentName}.docx`)

      document.body.appendChild(link)

      link.click()
      link.remove()
      await dispatch('load', true)
    }
  }
}))
