
































import Vue from 'vue'
import Alert from '@/components/Alert.vue'

export default Vue.extend({
  computed: {
    accessUrl() {
      return this.$store.getters['Config/myaccessUrl']
    }
  },
  components: {
    Alert
  }
})
