import store from '@/store'
import ODataStore, { ODataState, ODataRequest } from '@/store/ODataStore'
import ITemplate from './models/ITemplate'

export interface ITemplateStoreState extends Partial<ODataState<ITemplate>> {
  default: ITemplate
}

store.registerModule('Templates', ODataStore.extend<ITemplateStoreState>({
  namespaced: true,
  state: {
    default: null
  },
  getters: {
    results(state) {
      return state.result.value
    },
    getById: state => (id: number) => {
      return state.result.value.find(x => x.id === id)
    },
    default: state => {
      return state.default
    }
  },
  actions: {
    async getTemplateById({ dispatch }, id) {
      const request: ODataRequest = {
        url: `GraphTemplates(${id})`,
        params: {
          $expand: 'graphQueries($select=id,name;$orderby=name)'
        }
      }
      const template = await dispatch('get', request)
      return template
    },
    async load({ commit, dispatch }, preferredLocale) {
      const request: ODataRequest = {
        url: 'graphTemplates/MyTemplates',
        params: {
          $orderby: 'userId, name'
        }
      }
      const result = await dispatch('get', request)
      commit('setResult', result)

      let defaultTemplate = result.value.filter((x: ITemplate) => {
        return x.dmclanguageCode === preferredLocale
      })
      if (!defaultTemplate || defaultTemplate.length === 0) {
        defaultTemplate = result.value.filter((x: ITemplate) => x.dmclanguageCode === 'en-US')
      }
      if (defaultTemplate && defaultTemplate.length > 0) {
        commit('setDefault', defaultTemplate[0])
      }
    },
    async setDefault({ commit, dispatch }, preferredLocale) {
      const request: ODataRequest = {
        url: `GraphTemplates/ByLocale(locale='${preferredLocale}')`
      }
      try {
        const result = await dispatch('get', request)
        commit('setDefault', result)
      } catch {
        const request: ODataRequest = {
          url: 'GraphTemplates/ByLocale(locale=\'en-US\')'
        }
        const result = await dispatch('get', request)
        commit('setDefault', result)
      }
    },
    async getByLocale({ dispatch }, locale) {
      const request: ODataRequest = {
        url: `GraphTemplates/ByLocale(locale='${locale}')`
      }
      const template = await dispatch('get', request)
      return template
    },
    async saveTemplate({ dispatch }, template: ITemplate) {
      let result = template
      if (template.id) {
        const request: ODataRequest = {
          url: `GraphTemplates(${template.id})`,
          data: template,
          flatten: true
        }
        await dispatch('put', request)
      } else {
        const formData = new FormData()
        formData.append('templateFile', template.$_file)
        formData.append('name', template.name)

        const request: ODataRequest = {
          url: 'GraphTemplates',
          data: formData
        }
        result = await dispatch('post', request)
      }
      await dispatch('load')

      return result
    },
    async deleteTemplate({ dispatch }, template: ITemplate) {
      const request: ODataRequest = {
        url: `GraphTemplates(${template.id})`
      }
      await dispatch('delete', request)
      await dispatch('load')
    },
    async reassignTemplate({ dispatch }, payload: any) {
      const template: ITemplate = payload.template
      const replacementTemplateId: number = payload.replacementTemplateId

      const request: ODataRequest = {
        url: `GraphTemplates(${template.id})/Reassign`,
        data: {
          templateId: replacementTemplateId
        }
      }
      await dispatch('post', request)
      await dispatch('deleteTemplate', template)
    },
    async downloadTemplate({ dispatch }, template: ITemplate) {
      const request: ODataRequest = {
        url: `GraphTemplates(${template.id})/Download`,
        responseType: 'blob',
        noLoading: true
      }
      const response = await dispatch('get', request)
      const blob = new Blob([response], {
        type: response.type
      })
      const downloadUrl = window.URL.createObjectURL(blob)

      const link = document.createElement('a')

      link.href = downloadUrl
      link.setAttribute('download', `${template.name}.docx`)

      document.body.appendChild(link)

      link.click()
      link.remove()
    }
  },
  mutations: {
    setDefault(state, result) {
      state.default = result
    }
  }
}))
