






import Vue from 'vue'

export default Vue.extend({
  props: {
    value: {
      type: String,
      default: 'No data available.'
    }
  }
})
