import store from '@/store'
import { Module } from 'vuex'
import Axios, { AxiosRequestConfig } from 'axios'
import { merge, isObject } from 'lodash-es'
import IODataQuery from '@/models/IODataQuery'

export interface ODataResult<T> {
  count: number
  value: T[]
}

export interface ODataState<TResult> {
  loading: boolean,
  error: Error,
  result: ODataResult<TResult>,
  itemsPerPage: number
}

export interface ODataRequest extends AxiosRequestConfig {
  noLoading?: boolean,
  noError?: boolean,
  params?: Partial<IODataQuery>,
  concurrency?: string,
  flatten?: boolean
}

function getRestStore<TResult>(): Module<ODataState<TResult>, any> {
  return {
    namespaced: true,
    state: {
      loading: false,
      error: null,
      itemsPerPage: 10,
      result: {
        count: 0,
        value: []
      }
    },
    getters: {
      loading(state) {
        return state.loading
      },
      error(state) {
        return state.error
      }
    },
    mutations: {
      setLoading: (state, value: boolean) => {
        state.loading = value
      },
      setError: (state, value: Error) => {
        state.error = value
      },
      setResult(state, result: ODataResult<TResult>) {
        state.result = {
          count: result['@odata.count'] ?? result.value.length,
          value: result.value
        }
      }
    },
    actions: {
      async get({ dispatch }, request: ODataRequest = {}) {
        request.method = 'get'
        return dispatch('send', request)
      },
      async put({ dispatch }, request: ODataRequest = {}) {
        request.method = 'put'
        return dispatch('send', request)
      },
      async patch({ dispatch }, request: ODataRequest = {}) {
        request.method = 'patch'
        return dispatch('send', request)
      },
      async post({ dispatch }, request: ODataRequest = {}) {
        request.method = 'post'
        return dispatch('send', request)
      },
      async delete({ dispatch }, request: ODataRequest = {}) {
        request.method = 'delete'
        return dispatch('send', request)
      },
      /**
         * A generic AJAX request.
         * @param request Axios options. See https://github.com/axios/axios#request-config
         */
      async send({ commit }, request: ODataRequest = {}) {
        // validate
        if (!request.url) {
          throw Error('Cannont send AJAX request. Missing required "url" parameter.')
        }
        if (!request.method) {
          throw Error('Cannont send AJAX request. Missing required "method" parameter.')
        }
        if (['get', 'put', 'patch', 'post', 'delete'].indexOf(request.method) < 0) {
          throw Error(`Cannont send AJAX request. The method of '${request.method}' is invalid.`)
        }

        // clean the data by removing properties that start with $_
        const replacer = (key: string, value: any) => {
          if (key.startsWith('$_') || key.startsWith('@odata')) {
            return undefined
          }
          if (request.flatten && key && isObject(value)) {
            return undefined
          }
          return value
        }
        if (request.data) {
          if (!(request.data instanceof FormData)) {
            request.data = JSON.parse(JSON.stringify(request.data, replacer))
          }
        }

        // need to delete empty odata params or request will fail
        const params = request.params
        if (params) {
          for (const key in params) {
            if (!params[key] && params[key] !== 0) {
              delete params[key]
            }
          }
        }

        // add concurrency to header if provided
        request.headers = request.headers ?? {}
        if (request.concurrency) {
          request.headers['If-Match'] = request.concurrency
        }

        try {
          commit('setError', null)
          if (!request.noLoading) {
            commit('setLoading', true)
          }
          const response = await Axios.request(request)
          return response.data
        } catch (error) {
          if (!request.noError) {
            commit('setError', error)
          }
          throw error
        } finally {
          if (!request.noLoading) {
            commit('setLoading', false)
          }
        }
      }
    }
  }
}

export function getters(namespace: string) {
  return {
    loading(): boolean {
      return store.getters[`${namespace}/loading`]
    },
    error(): Error {
      return store.getters[`${namespace}/error`]
    }
  }
}

export default {
  extend<T extends Partial<ODataState<any>>>(store: Module<T, any>) {
    return merge(getRestStore(), store)
  },
  getters
}
