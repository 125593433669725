<template>
  <ConfirmDialog
    :show-dialog="!!value"
    :confirmButtonText="okText"
    :question="title"
    :description="message"
    @close="$emit('cancel')"
    @confirm="$emit('submit')"
  >
    <slot />
  </ConfirmDialog>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog'

export default {
  props: {
    value: null,
    title: String,
    message: String,
    okText: {
      type: String,
      default: 'Yes'
    }
  },
  components: {
    ConfirmDialog
  }
}
</script>
