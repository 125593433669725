import Vue from 'vue'
import Vuex from 'vuex'
import Alert from '@/models/Alert'
import AlertType from '@/models/AlertType'
import { get } from 'lodash-es'

Vue.use(Vuex)

export interface IRootState {
  alert: Alert
}

const store = new Vuex.Store<IRootState>({
  state: {
    alert: null
  },
  getters: {
    alert(state) {
      return state.alert
    }
  },
  mutations: {
    setAlert(state, alert: Alert) {
      state.alert = alert
    }
  },
  actions: {
    async showSuccessAlert({ dispatch }, message) {
      const alert: Alert = {
        messages: [message],
        type: AlertType.Success
      }
      await dispatch('showAlert', alert)
    },
    async showErrorAlert({ dispatch }, error: Error) {
      if (!error) {
        return
      }
      let messages: string[] = []
      const errorDetails = get(error, 'response.data.error.details', [])
      const errorMessage = get(error, 'response.data.error.message')

      if (errorDetails.length) {
        messages = errorDetails.map(e => e.message)
      } else if (errorMessage) {
        messages.push(errorMessage)
      } else {
        messages.push(error as any)
      }

      const alert: Alert = {
        messages,
        type: AlertType.Error,
        timeout: -1
      }
      await dispatch('showAlert', alert)
    },
    async showAlert({ commit }, alert: Alert) {
      commit('setAlert', alert)
    },
    async hideAlert({ commit }) {
      commit('setAlert', null)
    }
  }
})

export default store
