




















import Vue from 'vue'
import { ValidationProvider } from 'vee-validate'

export default Vue.extend({
  props: {
    rules: String,
    label: String,
    disabled: Boolean,
    debounce: {
      type: Number,
      default: 0
    }
  },
  components: {
    ValidationProvider
  },
  methods: {
    hasError() : boolean {
      const field = this.$refs.field as any
      if (field) {
        return field.hasError
      }
      return false
    }
  }
})
