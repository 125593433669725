










import Vue from 'vue'

export default Vue.extend({
  components: {
  },
  props: {
    value: Number
  },
  data() {
    return {
      star1: false,
      star2: false,
      star3: false,
      star4: false,
      star5: false
    }
  },
  computed: {
  },
  methods: {
    onChange(stars: number) {
      if (stars === 5) {
        this.star1 = this.star2 = this.star3 = this.star4 = this.star5 = true
      } else if (stars === 4) {
        this.star5 = false
        this.star1 = this.star2 = this.star3 = this.star4 = true
      } else if (stars === 3) {
        this.star5 = this.star4 = false
        this.star1 = this.star2 = this.star3 = true
      } else if (stars === 2) {
        this.star5 = this.star4 = this.star3 = false
        this.star1 = this.star2 = true
      } else if (stars === 1) {
        this.star5 = this.star4 = this.star3 = this.star2 = false
        this.star1 = true
      }
      this.$emit('input', stars)
    }
  }
})
