<template>
  <Dialog
    title="Welcome to the Microsoft Lifecycle Tool"
    :showCancel="false"
    submitText="Close"
    v-model="show"
    @submit="show = false">
    <div class="ma-3 pb-2">
      <p>
        EOS Insights helps field roles with customer-ready discussions around upcoming, high-impact end of support events. It helps evangelize Microsoft's predictability and transparency around end of support timelines and suggested migration paths. Select the <b>Report Builder</b> tab above to get started building your reports.
      </p>
      <p>
        This tool uses the same data source as the external lifecycle site (<a href="https://docs.microsoft.com/lifecycle" target="_blank">docs.microsoft.com/lifecycle</a>). Click on the tabs above to access the following:
      </p>
      <p>
        The <b>Report Builder</b> lets you create customer-ready reports by product.
      </p>
      <p>
        The <b>Saved Reports</b> page provides access to your saved reports and allows you to run them again using refreshed data.
      </p>
      <p>
        The <b>Customers</b> page lets you browse product data by customer (requires an active MS Sales account).
      </p>
      <p>
        Please share your feedback with the Lifecycle team by rating the tool before you and/or providing verbatim feedback using the Rate This Tool link on the bottom right of the screen. If you are interested in helping to beta test new features, please reach out to <a href="mailto:karencart@microsoft.com">Karen Carter-Schwendler</a>.
      </p>
    </div>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialog'

export default {
  components: {
    Dialog
  },
  props: {
    value: Boolean
  },
  data() {
    return {
      show: false
    }
  },
  computed: {
  },
  watch: {
    value(val) {
      this.show = val
    }
  }
}
</script>
