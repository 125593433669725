import store from '@/store'
import ODataStore, { ODataState, ODataRequest } from '@/store/ODataStore'
import ICustomerIssue from './models/ICustomerIssue'
import ICustomerModel from './models/ICustomerModel'
import ISearchModel from './models/ISearchModel'
import ISuggestModel from './models/ISuggestModel'

export interface ICustomerStoreState extends Partial<ODataState<ICustomerModel>> {
  hasAccess: boolean
  hasFavorites: boolean
  hasAssignments: boolean
  searchResults: any
  searchModel: any
  selectedTimeFrames: []
  selectedCustomers: []
  selectedProducts: []
  productsToShow: []
  customersToShow: []
  licenses: []
}

store.registerModule('Customers', ODataStore.extend<ICustomerStoreState>({
  namespaced: true,
  state: {
    hasAccess: false,
    hasFavorites: false,
    hasAssignments: false,
    searchResults: null,
    searchModel: null,
    selectedTimeFrames: [],
    selectedCustomers: [],
    selectedProducts: [],
    productsToShow: [],
    customersToShow: [],
    licenses: []
  },
  getters: {
    results(state) {
      return state.result.value
    },
    hasAccess(state) {
      return state.hasAccess
    },
    hasFavorites(state) {
      return state.hasFavorites
    },
    hasAssignments(state) {
      return state.hasAssignments
    },
    searchResults(state) {
      return state.searchResults
    },
    selectedTimeFrames(state) {
      return state.selectedTimeFrames
    },
    selectedCustomers(state) {
      return state.selectedCustomers
    },
    selectedProducts(state) {
      return state.selectedProducts
    },
    productsToShow(state) {
      return state.productsToShow
    },
    customersToShow(state) {
      return state.customersToShow
    },
    licenses(state) {
      return state.licenses
    },
    searchModel(state) {
      return state.searchModel
    }
  },
  mutations: {
    setAccess(state, value: boolean) {
      state.hasAccess = value
    },
    setFavorites(state, value: boolean) {
      state.hasFavorites = value
    },
    setAssignments(state, value: boolean) {
      state.hasAssignments = value
    },
    setSearchResults(state, value: any) {
      state.searchResults = value
    },
    setSelectedTimeFrames(state, value: []) {
      state.selectedTimeFrames = value
    },
    setSelectedCustomers(state, value: []) {
      state.selectedCustomers = value
    },
    setSelectedProducts(state, value: []) {
      state.selectedProducts = value
    },
    setProductsToShow(state, value: []) {
      state.productsToShow = value
    },
    setCustomersToShow(state, value: []) {
      state.customersToShow = value
    },
    setLicenses(state, value: []) {
      state.licenses = value
    },
    setSearchModel(state, value: any) {
      state.searchModel = value
    }
  },
  actions: {
    async checkAccess({ dispatch, commit }) {
      const request: ODataRequest = {
        url: 'Customers/CheckAccess'
      }
      const response = await dispatch('get', request)
      commit('setAccess', response.value)
    },
    async toggleFavorite({ dispatch }, customer: ICustomerModel) {
      const request: ODataRequest = {
        url: `Customers(${customer.id})/ToggleFavorite`,
        data: {
          favorite: customer.favorite
        }
      }
      await dispatch('post', request)
      await dispatch('checkFavorites')
    },
    async checkFavorites({ dispatch, commit }) {
      const request: ODataRequest = {
        url: 'Customers/HasFavorites'
      }
      const response = await dispatch('get', request)
      commit('setFavorites', response.value)
    },
    async checkAssignments({ dispatch, commit }) {
      const request: ODataRequest = {
        url: 'Customers/HasDirectAssignments'
      }
      const response = await dispatch('get', request)
      commit('setAssignments', response.value)
    },
    async fileIssue({ dispatch }, customerIssue: ICustomerIssue) {
      const request: ODataRequest = {
        url: 'Customers/Issue',
        data: customerIssue
      }
      await dispatch('post', request)
      dispatch('showSuccessAlert', 'Issue Reported', { root: true })
    },
    async suggest({ dispatch }, model: ISuggestModel) {
      if (model.searchText !== '') {
        model.searchText = `"${model.searchText}"`
      }
      const request: ODataRequest = {
        url: 'Customers/Suggest',
        data: model
      }
      const response = await dispatch('post', request)
      return response
    },
    async search({ dispatch, commit }, model: ISearchModel) {
      if (model.searchText !== '') {
        model.searchText = `"${model.searchText}"`
      }
      const request: ODataRequest = {
        url: 'Customers/Search',
        data: model
      }
      const response = await dispatch('post', request)
      commit('setSearchResults', response)
      commit('setSearchModel', model)
    },
    clearSearch({ commit }) {
      commit('setSearchResults', null)
      commit('setSelectedTimeFrames', [])
      commit('setSelectedCustomers', [])
      commit('setSelectedProducts', [])
      commit('setProductsToShow', [])
      commit('setCustomersToShow', [])
      commit('setLicenses', [])
    },
    async byTpid({ dispatch }, tpid: number) {
      const request: ODataRequest = {
        url: `Customers/ByTpid(tpid=${tpid})?$expand=CustomerOrganizations`
      }
      const response = await dispatch('get', request)
      return response
    },
    async export({ dispatch }, model: ISearchModel) {
      const request: ODataRequest = {
        url: 'Customers/Export',
        data: model,
        responseType: 'blob'
      }
      const response = await dispatch('post', request)
      const blob = new Blob([response])
      const downloadUrl = window.URL.createObjectURL(blob)

      const link = document.createElement('a')

      link.href = downloadUrl
      const documentName = 'EOS_report'

      link.setAttribute('download', `${documentName}.xlsx`)

      document.body.appendChild(link)

      link.click()
      link.remove()
    },
    setSelectedTimeFrames({ commit }, value: []) {
      commit('setSelectedTimeFrames', value)
    },
    setSelectedCustomers({ commit }, value: []) {
      commit('setSelectedCustomers', value)
    },
    setSelectedProducts({ commit }, value: []) {
      commit('setSelectedProducts', value)
    },
    setProductsToShow({ commit }, value: []) {
      commit('setProductsToShow', value)
    },
    setCustomersToShow({ commit }, value: []) {
      commit('setCustomersToShow', value)
    },
    setLicenses({ commit }, value: []) {
      commit('setLicenses', value)
    }
  }
}))
