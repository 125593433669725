<template>
  <ValidationObserver v-slot="{ handleSubmit, reset }">
    <form
      @submit.prevent="handleSubmit(onSubmit)"
      @reset.prevent="onReset(reset)"
    >
      <slot />
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationObserver
  },
  methods: {
    onSubmit() {
      this.$emit('submit')
    },
    onReset(reset) {
      reset()
      this.$emit('reset')
    }
  }
}
</script>
