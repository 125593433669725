<template>
  <v-dialog
    :value="value"
    :width="width"
    :scrollable="scrollable"
    @click:outside="$emit('cancel')"
  >
    <Form v-if="value" @submit="$emit('submit')" @reset="$emit('cancel')">
      <v-card :height="height">
        <v-card-title class="headline grey lighten-4" primary-title>
          <v-icon v-if="icon" left>{{ icon }}</v-icon>
          {{ title }}
        </v-card-title>
        <v-divider />

        <v-card-text class="pa-0">
          <slot />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <slot name="actions">
            <v-btn v-if="showCancel" :disabled="loading" color="secondary" type="reset">{{
              cancelText
            }}</v-btn>
            <v-spacer />
            <v-btn
              :disabled="loading || disabled"
              :color="submitColor"
              type="submit"
              >{{ submitText }}</v-btn
            >
          </slot>
        </v-card-actions>
      </v-card>
    </Form>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: null,
    showCancel: {
      type: Boolean,
      default: true
    },
    width: {
      type: [String, Number],
      default: '500'
    },
    height: {
      type: [String, Number],
      default: 'auto'
    },
    submitText: {
      type: String,
      default: 'Ok'
    },
    submitColor: {
      type: String,
      default: 'primary'
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    title: String,
    icon: String,
    loading: Boolean,
    scrollable: Boolean,
    disabled: Boolean
  }
}
</script>
