































import Vue from 'vue'

export default Vue.extend({
  props: {
    value: null,
    title: String,
    width: String,
    loading: Boolean,
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    cancelColor: {
      type: String,
      default: 'secondary'
    },
    submitText: {
      type: String,
      default: 'Save'
    },
    submitColor: {
      type: String,
      default: 'primary'
    }
  },
  methods: {
    onInput(e) {
      if (!e) {
        this.$emit('cancel')
      }
    }
  }
})
