import router from '@/router'

const routes = [
  {
    path: '/Timeline',
    name: 'Timeline',
    meta: {
      title: 'Timeline'
    },
    components: {
      default: () => import(/* webpackChunkName: "Timeline" */ './views/Timeline.vue')
    }
  },
  {
    path: '/Timeline/:tpid/Organizations/:orgIds',
    name: 'CustomerTimeline',
    meta: {
      title: 'Timeline'
    },
    redirect: to => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { path: '/Timeline', query: { tpid: to.params.tpid, orgIds: to.params.orgIds } }
    }
  }
]

router.addRoutes(routes)

export default routes
