import store from '@/store'
import ODataStore, { ODataState } from '@/store/ODataStore'
import IWelcome from './models/IWelcome'

export interface IWelcomeStoreState extends Partial<ODataState<IWelcome>> {
  shown: boolean
}

store.registerModule('Welcome', ODataStore.extend<IWelcomeStoreState>({
  namespaced: true,
  state: {
    shown: Boolean(localStorage.getItem('welcome-shown'))
  },
  getters: {
    shown(state) {
      return state.shown
    }
  },
  mutations: {
    shown(state, shown: boolean) {
      state.shown = shown
      localStorage.setItem('welcome-shown', `${shown}`)
    }
  },
  actions: {
    setShown({ commit }) {
      commit('shown', true)
    }
  }
}))
