




































import Vue from 'vue'
import Alert from '@/models/Alert'
import AlertType from '@/models/AlertType'

export default Vue.extend({
  computed: {
    alert(): Alert {
      return this.$store.getters.alert
    },
    color(): string {
      switch (this.alert.type) {
        case AlertType.Error:
          return 'error'
        case AlertType.Success:
          return 'success'
        default:
          return 'info'
      }
    },
    icon(): string {
      switch (this.alert.type) {
        case AlertType.Error:
          return '$error'
        case AlertType.Success:
          return '$success'
        default:
          return 'info'
      }
    }
  },
  methods: {
    hideAlert() {
      this.$store.dispatch('hideAlert')
    }
  }
})
