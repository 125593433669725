import store from '@/store'
import Axios from 'axios'
import IConfig from './models/IConfig'
import { Boolify } from 'node-boolify'

export interface IConfigStoreState {
  config: IConfig
}

store.registerModule('Config', {
  namespaced: true,
  state: {
    config: null
  },
  getters: {
    clientId(state) {
      return state.config?.auth?.clientId
    },
    tenantId(state) {
      return state.config?.auth?.tenant
    },
    redirectUri(state) {
      return state.config?.settings?.redirectUri
    },
    instrumentationKey(state) {
      return state.config?.applicationInsights?.instrumentationKey
    },
    baseUrl(state) {
      return state.config?.api?.base || `${window.location.protocol}//${window.location.hostname}/api`
    },
    showEnvironmentAlert(state) {
      return Boolify(state.config?.settings?.showEnvironmentAlert)
    },
    myaccessUrl(state) {
      return state.config?.settings?.myaccessUrl
    }
  },
  mutations: {
    setConfig(state, config: IConfig) {
      state.config = config
    }
  },
  actions: {
    async getConfig({ commit }) {
      const response = await Axios.get('/config.json', { baseURL: null })
      commit('setConfig', response.data)
    }
  }
})
