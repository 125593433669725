import Vue from 'vue'

export default Vue.extend({
  props: {
    value: [Object, Array]
  },
  data() {
    return {
      form: null as any
    }
  },
  watch: {
    value: {
      handler(val) {
        this.form = val || {}
      },
      deep: true,
      immediate: true
    },
    form: {
      handler(newValue) {
        this.$emit('input', newValue)
      },
      deep: true
    }
  }
})
