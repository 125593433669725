













import Vue from 'vue'

export default Vue.extend({
  props: {
    type: {
      type: String,
      default: 'info'
    },
    dense: Boolean
  },
  computed: {
    color(): string {
      switch (this.type) {
        case 'info':
          return 'info'
        case 'warning':
          return 'warning darken-1'
        case 'error':
          return 'error darken-1'
        default:
          return undefined
      }
    },
    icon(): string {
      switch (this.type) {
        case 'info':
          return '$info'
        case 'warning':
          return '$warning'
        case 'error':
          return '$error'
        default:
          return undefined
      }
    }
  }
})
