import store from '@/store'
import Vue from 'vue'
import { UserAgentApplication, AuthenticationParameters } from 'msal'

export interface IAuthStoreState {
  accessToken: string
}

store.registerModule<IAuthStoreState>('Auth', {
  namespaced: true,
  state: {
    accessToken: null
  },
  getters: {
    accessToken(state) {
      return state.accessToken
    }
  },
  mutations: {
    setAccessToken(state, accessToken: string) {
      state.accessToken = accessToken
    }
  },
  actions: {
    async login({ rootGetters, commit }) {
      const clientId = rootGetters['Config/clientId']
      const scopes = [`${clientId}/.default`]
      const redirectUri = window.location.origin
      const msal = Vue.prototype.$auth as UserAgentApplication

      if (!msal) {
        return
      }

      const account = msal.getAccount()

      // have to do full login if no account
      if (!account) {
        msal.loginRedirect({ scopes, redirectUri })
        return
      }

      // try to get the token silently first
      const tokenRequest: AuthenticationParameters = { scopes, sid: account.sid, redirectUri }

      try {
        const accessToken = (await msal.acquireTokenSilent(tokenRequest)).accessToken
        commit('setAccessToken', accessToken)
        return accessToken
      } catch (err) {
        if (err.name === 'InteractionRequiredAuthError') {
          try {
            msal.acquireTokenRedirect(tokenRequest)
          } catch (err) {
            commit('setError', err)
          }
        } else {
          commit('setError', err)
        }
      }
    },
    async logout({ commit }) {
      for (const key in localStorage) {
        if (key.startsWith('msal.')) {
          localStorage.removeItem(key)
        }
      }
      commit('setAccessToken', null)
      window.location.reload()
    }
  }
})
