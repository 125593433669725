import truncate from 'lodash/truncate'
import moment from 'moment'

export default class Formatter {
  private static isDate(value: any) {
    return moment(value, [moment.ISO_8601], true).isValid()
  }

  static number(value: string | number, { precision = 0, abbreviated = false } = {}) {
    const nbr = +value
    if (isNaN(nbr)) {
      return value
    }
    if (abbreviated) {
      return abbreviateNumber(nbr)
    }
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision
    })

    return formatter.format(nbr || 0)
  }

  static currency(value: number, { precision = 0 } = {}) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: precision,
      maximumFractionDigits: precision
    })

    return formatter.format(+value || 0)
  }

  static percent(value: any, { precision = 0 } = {}) {
    return this.number(value, { precision }) + ' %'
  }

  static date(value: any, format: string = 'l') {
    if (!this.isDate(value)) {
      return value
    }

    return moment(value).format(format)
  }

  static datetime(value: any) {
    if (value) {
      return this.date(value, 'lll')
    }

    return value
  }

  static phone(value: any) {
    if (value) {
      const result = value.toString()

      if (result.length === 10) {
        const area = result.substr(0, 3)
        const prefix = result.substr(3, 3)
        const line = result.substr(6, 4)

        return `(${area}) ${prefix}-${line}`
      }
      return value
    }
    return value
  }

  static bool(value: any) {
    return value ? 'Yes' : 'No'
  }

  static split(value: any) {
    if (!value) {
      return value
    }
    return value.replace(/([a-z](?=[A-Z]))/g, '$1 ')
  }

  static mimeType(value: string) {
    if (!value) {
      return value
    }
    return value.replace('application/', '').toUpperCase()
  }

  static bytes(value: number, { precision = 2 } = {}) {
    if (!value) {
      return '0 Bytes'
    }
    const k = 1024
    const dm = precision < 0 ? 0 : precision
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB']
    const i = Math.floor(Math.log(value) / Math.log(k))
    return parseFloat((value / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  static truncate(value: string, { length = 20, omission = '...' } = {}) {
    return truncate(value, { length, omission })
  }

  static format(value: any, name: string, options?: any) {
    switch (name) {
      case 'number':
        return this.number(value, options)
      case 'currency':
        return this.currency(value, options)
      case 'percent':
        return this.percent(value, options)
      case 'date':
        return this.date(value, 'l')
      case 'datetime':
        return this.datetime(value)
      case 'longdatetime':
        return this.date(value, 'LLL')
      case 'longdate':
        return this.date(value, 'LL')
      case 'shortdate':
        return this.date(value, 'll')
      case 'isodate':
        return this.date(value, 'YYYY-MM-DD')
      case 'phone':
        return this.phone(value)
      case 'bool':
        return this.bool(value)
      case 'split':
        return this.split(value)
      case 'bytes':
        return this.bytes(value, options)
      case 'auto':
        return this.autoFormat(value)
      default:
        return value
    }
  }

  static autoFormat(value: any) {
    if (typeof value === 'boolean') {
      return this.bool(value)
    }

    if (typeof value === 'number') {
      return this.number(value)
    }

    if (!value) {
      return value
    }

    if (this.isDate(value)) {
      if (value.length > 10) {
        return this.datetime(value)
      }
      return this.date(value, 'll')
    }

    return value
  }
}

// https://stackoverflow.com/a/40724354
function abbreviateNumber(number: number) {
  const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E']

  // what tier? (determines SI symbol)
  const tier = Math.log10(number) / 3 | 0

  // if zero, we don't need a suffix
  if (tier === 0) return number

  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier]
  const scale = Math.pow(10, tier * 3)

  // scale the number
  const scaled = number / scale

  // format number and add suffix
  return scaled.toFixed(1) + suffix
}
