import router from '@/router'

router.addRoutes([
  {
    path: '/Dashboard',
    name: 'Dashboard',
    meta: {
      title: 'Dashboard'
    },
    components: {
      default: () => import(/* webpackChunkName: "Dashboard" */ './views/Dashboard.vue')
    }
  }
])
