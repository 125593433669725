<style>
div.v-alert__content {
  max-width: 1399px;
  margin: auto;
}
</style>

<template>
  <Alert type="error" style="background: white !important">
    <v-row align="center">
      <v-col class="center">
        This is an active beta site the Lifecycle team uses to iterate on new features. Data on this site is not current. Please use the live site at <a href="https://aka.ms/eosinsights">https://aka.ms/eosinsights</a>
      </v-col>
    </v-row>
  </Alert>
</template>

<script>
import Vue from 'vue'
import Alert from './Alert.vue'

export default Vue.extend({
  components: {
    Alert
  }
})
</script>
