



















import Vue from 'vue'
import { ValidationProvider } from 'vee-validate'

export default Vue.extend({
  props: {
    rules: String,
    label: String,
    disabled: Boolean,
    debounce: {
      type: Number,
      default: 0
    }
  },
  components: {
    ValidationProvider
  }
})
