








import Vue from 'vue'
import UserFeedbackDialog from '@/areas/userFeedback/components/UserFeedbackDialog.vue'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('UserFeedback')

export default Vue.extend({
  data() {
    return {
    }
  },
  components: {
    UserFeedbackDialog
  },
  computed: {
    ...mapGetters(['userFeedback'])
  },
  methods: {
    ...mapActions({
      saveFeedback: 'save',
      closeDialog: 'closeDialog'
    })
    // incrementPageViews() {
    //   this.$store.dispatch('UserFeedback/incrementPageViews')
    // }
  },
  watch: {
    // $route() {
    //   this.incrementPageViews()
    // }
  }
})
